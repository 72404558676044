import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { BoxIconWrap, BoxIconInner, BoxIconTop, IconWrap, Heading, BoxIconBottom, Text, BoxIconBtn } from './box-icon.style';
import Button from "@ui/button";
import { Link } from "gatsby";


const BoxIcon = ({ icon, path, title, desc, ...restProps }) => {
	let boxImage;
	const { buttonStyles } = restProps;
	if (typeof icon === 'object') {
		boxImage = <GatsbyImage image={getImage(icon)} alt={title || 'Box Icon'} />;
	} else {
		boxImage = <img src={icon} alt={title || 'Box Icon'} />
	}

	return (
		<BoxIconWrap>
			<BoxIconInner>
				<BoxIconTop>
					{icon && (
						<IconWrap>
							{boxImage}
						</IconWrap>
					)}
					{title && <Heading>{title}</Heading>}
				</BoxIconTop>
				{desc && (
					<BoxIconBottom>
						<Text>{desc}</Text>
					</BoxIconBottom>
				)}
				<BoxIconBtn>
						<Button to={path} as={Link} {...buttonStyles} icon="far fa-long-arrow-right">
							Discover now
                        </Button>
					</BoxIconBtn>
			</BoxIconInner>
		</BoxIconWrap>
	)
}

BoxIcon.defaultProps = {
	title: '',
	buttonStyles: {
		varient: 'texted',
		fontWeight: 400,
		hover: "false",
		icondistance: "8px"
	},
	desc: ''
}

BoxIcon.propTypes = {
	title: PropTypes.string,
	desc: PropTypes.string,
	icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	buttonStyles: PropTypes.object

}

export default BoxIcon;