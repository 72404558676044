import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import parse from "html-react-parser";
import { Container, Row, Col } from "@ui/wrapper";
import Heading from "@ui/heading";
import BoxIcon from "@components/box-icon/layout-five";
import { SolutionsWrapper, SolutionBox, SolutionBoxItem,SectionWrap } from './solutions-area.style'
import { data } from "./data";


const Solutions = ({ headingStyle }) => {
	const solutionsData = useStaticQuery(graphql`
        query AboutSolutionsQuery {
            aboutUsJson(id: {eq: "about-page-solutions"}) {
                title
                
            }
        }      
    `);
	const { title } = solutionsData.aboutUsJson;
	return (
		<SectionWrap>
			<Container>
				<Row>
					<Col lg={12}>
						{title && <Heading {...headingStyle}>{parse(title)}</Heading>}
					</Col>
				</Row>
				<Row>
					{data.map((item, i) => (
						<Col md={6} key={item.id}>
							<BoxIcon
								id={item.id}
								title={item.title}
								desc={item.desc}
								icon={item.icon}
								path ={item.path}
							/>
						</Col>
					))}
				</Row>
			</Container>
		</SectionWrap>
	)
}

Solutions.propTypes = {
	headingStyle: PropTypes.object
}

Solutions.defaultProps = {
	headingStyle: {
		as: "h3",
		textalign: "center",
		mb: "30px",
		responsive: {
			medium: {
				mb: "10px"
			}
		}
	}
}

export default Solutions;