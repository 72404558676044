import iconOne from "@data/images/icons/icon-07-60x60.png";
import iconTwo from "@data/images/icons/icon-08-60x60.png";
import iconThree from "@data/images/icons/icon-09-60x60.png";
import iconFour from "@data/images/icons/icon-10-60x60.png";

export const data = [
	{
		id: "Artificial Intelligence",
		title: "Artificial Intelligence",
		desc: "How to automate various intelligent tasks using  advanced algorithms.",
		path: "/research/artificial-intelligence",
		icon: iconOne
	},
	{
		id: "Business Intelligence",
		title: "Business Intelligence",
		desc: "How to support business decision making using data science and artificial intelligence.",
		path: "/research/business-intelligence",
		icon: iconTwo
	},
	{
		id: "Privacy Security",
		title: "Privacy & Security",
		desc: "Cyber security and How to preserve the privacy of individuals while analyzing the data.        ",
		path: "/research/privacy-security",
		icon: iconThree
	},
	{
		id: "Applications",
		title: "Applications",
		desc: "AI and data science applications in various domains.        ",
		path: "/industrial-research",
		icon: iconFour
	}
]
